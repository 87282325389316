// faq max 56rem

.faq {

  .faq_container {

    .faq_text {

      .standard_button {
        display: table;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .faq_image {
      width: 100%;
      max-width: 36rem;
      margin: 0 auto 3rem;
      height: calc(100vw - 2rem);
      max-height: 36rem;
      padding-right: 0;
    }

    .faq_text {
      width: 100%;
      max-width: 48rem;
      margin: 0 auto;
    }
  }
}