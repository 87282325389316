// form max 56rem

.form {

  .form_container {

    .form_content {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }

    form {

      .form-group {

        .input {

          input, textarea {
            padding: 1.5rem;
          }
        }
      }
    }

    .form_image {
      width: 100%;
      max-width: 36rem;
      padding-left: 0;
      margin: 4rem auto 0;

      img {
        height: auto;
        max-height: unset;
      }
    }
  }
}