// basic styles
//@import "style_base.scss";

// breakpoint max-width: 56rem
@media screen and (max-width: 56rem) {
  @import "ContentElements/audioplayer/audioplayer_max_56rem.scss";
  @import "ContentElements/bulletpoints/bulletpoints_max_56rem.scss";
  @import "ContentElements/calendar_widget/calendar_widget_max_56rem";
  @import "ContentElements/faq/faq_max_56rem.scss";
  @import "ContentElements/form/form_max_56rem.scss";
  @import "ContentElements/image_text/image_text_max_56rem.scss";
  @import "ContentElements/image_textbox/image_textbox_max_56rem.scss";
  @import "ContentElements/roundimage_text/roundimage_text_max_56rem.scss";
  @import "ContentElements/team/team_max_56rem.scss";
}
