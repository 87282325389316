// image_text max 56rem

.image_and_text {

  .image_and_text_container {

    .image_and_text_image {
      width: 100%;

      img {
        position: relative;
        padding-right: 0;
      }
    }

    .image_and_text_text {
      width: 100%;
    }
  }
}