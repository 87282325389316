// image_textbox max 56rem

.image_textbox {

  .textbox {

    .textbox_text {
      width: 100%;
    }

    .textbox_header {
      width: 100%;
      padding: 4rem 3rem;

      h2 {
        font-size: 2.75rem;
        max-width: 100%;

        em {
          font-size: 3rem;
        }
      }
    }
  }
}