//calendar_widget max 56rem

.calendar_form {

  #calendarform {

    .calendarform_container {

      .calendarform_data {
        display: none;
      }
    }
  }
}