// audioplayer max 56rem

.audioplayer {

  .audioplayer_container {

    .audioplayer_player {
      margin: 0 auto 4rem;
      width: 100%;
    }

    .audioplayer_text {
      padding-left: 0;
      width: 100%;
    }
  }
}