// team max 56rem

.team {

  .team_container {

    .team_member {

      .team_member_overlay {

        .team_member_overlay_text {

          h3 {
            font-size: 1.75rem;
          }

          p {
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}