// roundimage_text max 56rem

.workflow {

  .workflow_container {

    .workflow_item {
      width: calc(45vw - 6rem);

      img, .dummy {
        width: 12rem;
        height: 12rem;
      }

      .workflow_overlay {
        width: 12rem;
        height: 12rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .workflow_line {
      margin-top: 6rem;
      width: calc(10vw + 12rem);
      margin-right: -6rem;
      margin-left: -6rem;

      &:nth-child(4n) {
        display: none;
      }
    }
  }
}