// bulletpoints max 56rem

.bulletpoints {

  .bulletpoints_container {

    .bulletpoints_items {

      .bulletpoints_item {

        @media screen and (max-width: 56rem) {
          min-width: 50%;
          width: 50%;
          max-width: 50%;
        }

        &:nth-of-type(odd) {
          padding-right: 1.25rem;
        }

        &:nth-of-type(even) {
          padding-left: 1.25rem;
        }
      }
    }
  }
}